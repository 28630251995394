/* eslint-disable */
const PageCompany = [
  {
    text: `<span style="color: red;" class="text-uppercase">Bem vindo ao People!</span>Aqui você poderá configurar seu bot e interagir melhor com os seus usuários! Nesse tutorial vamos te guiar por todos os passos para deixar seu bot pronto para uso! Para começar, precisamos que você crie uma empresa onde você reunirá todos os seus bots e poderá configurá-los também.`,
    typeGetElement: () => { return document.getElementsByTagName('body')[0] },
    styleBallonBefore: 'bottom',
    showBotSpeak: true,
    styleBotSpeakAndBallon: { top: 'calc(45vh - 215px)', width: '430px', height: '260px', left: 'calc(50vw - 215px)' },
    noFocusElement: true,
    showBottomNext: true
  },
  {
    typeGetElement: () => { return document.getElementById('btn-add') },
    messageFocusBottom: 'Clique aqui para adicionar uma nova empresa!',
    exitTime: 100
  },
  {
    text: `<span style="color: red;" class="text-uppercase">Adicione uma empresa para prosseguir!</span>Você precisa adicionar um nome para identificar sua empresa. Também pode ser adicionada uma descrição sobre o que é a empresa e uma imagem para facilitar a distinção caso sejam criadas várias empresas.`,
    typeGetElement: () => { return document.getElementsByClassName('v-dialog__content')[0] },
    styleBallonBefore: 'left',
    styleBotSpeakAndBallon: { top: 'calc(50vh - 130px)', width: '230px', height: '280px', left: 'calc(50vw + 350px)' },
    exitTime: 0
  },
  {
    text: `Sua empresa foi criada com sucesso!`,
    typeGetElement: () => { return document.getElementsByClassName('card')[0] },
    styleBallonBefore: 'bottom',
    showBotSpeak: true,
    styleBotSpeakAndBallon: { top: '120px', width: '301px', height: '120px', left: '360px' },
    showBottomNext: true,
    entryTime: 500
  },
  {
    text: `Caso queira editar alguma informação ou excluir alguma empresa, essas opções ficam disponíveis aqui.`,
    typeGetElement: () => { return document.getElementsByClassName('action-buttons')[0] },
    styleBallonBefore: 'bottom',
    showBotSpeak: true,
    styleBotSpeakAndBallon: { top: '120px', width: '430px', height: '130px', left: '360px' },
    showBottomNext: true,
    bgColor: '#fff'
  },
  {
    text: `Para realizarmos a criação do seu bot, acesse a página de bots dentro da empresa.`,
    typeGetElement: () => { return document.getElementsByClassName('see-bots')[0] },
    styleBallonBefore: 'bottom',
    showBotSpeak: true,
    styleBotSpeakAndBallon: { top: '250px', width: '430px', height: '130px', left: '360px' },
    position: 'relative'
  }
]

const PageBots = [
  {
    text: `<span style="color: red;" class="text-uppercase">Você sabe o que é um bot?</span><br>Ele é um robozinho digital que funciona como um “atendente”. Ele pode conversar com seus usuários, fazer perguntas, recolher e armazenar informações, e assim otimizar a execução de tarefas no seu sistema. Pode te ajudar em muitas coisas, viu? Vamos criar um bot!`,
    typeGetElement: () => { return document.getElementsByTagName('body')[0] },
    styleBallonBefore: 'bottom',
    showBotSpeak: true,
    styleBotSpeakAndBallon: { top: 'calc(45vh - 215px)', width: '430px', height: '280px', left: 'calc(50vw - 215px)' },
    noFocusElement: true,
    showBottomNext: true
  },
  {
    typeGetElement: () => { return document.getElementsByClassName('btn-add')[0] },
    messageFocusBottom: 'Clique aqui para adicionar um novo Bot!',
    exitTime: 100
  },
  {
    text: `<span style="color: red;" class="text-uppercase">Adicione um bot para prosseguir!</span>Você precisa adicionar um nome e uma cor para identificar seu bot. Também pode ser adicionado uma descrição sobre o bot e uma imagem para facilitar sua identificação, caso sejam criados vários bots.`,
    typeGetElement: () => { return document.getElementsByClassName('v-dialog__content')[0] },
    styleBallonBefore: 'left',
    styleBotSpeakAndBallon: { top: 'calc(50vh - 140px)', width: '230px', height: '280px', left: 'calc(50vw + 340px)' },
    exitTime: 0
  },
  {
    text: `Agora basta aguardar o processamento do seu bot para prosseguir!`,
    typeGetElement: () => { return document.getElementsByClassName('item-bots')[0] },
    styleBallonBefore: 'left',
    showBotSpeak: true,
    styleBotSpeakAndBallon: { top: '370px', width: '530px', height: '280px', left: '100px' },
    entryTime: 500
  },
  {
    text: `Seu bot foi criado com sucesso!`,
    typeGetElement: () => { return document.getElementsByClassName('item-bots')[0] },
    styleBallonBefore: 'left',
    showBotSpeak: true,
    styleBotSpeakAndBallon: { top: '370px', width: '400px', height: '280px', left: '100px' },
    showBottomNext: true
  },
  {
    text: `Caso queira editar alguma informação ou excluir seu bot, essas opções ficam disponíveis aqui.`,
    typeGetElement: () => { return document.getElementsByClassName('action-buttons')[0] },
    styleBallonBefore: 'left',
    showBotSpeak: true,
    styleBotSpeakAndBallon: { top: '370px', width: '400px', height: '280px', left: '500px' },
    showBottomNext: true,
    bgColor: '#fff'
  },
  {
    text: `Aqui você consegue ver os detalhes do seu bot referentes à intenções, entidades e recursos cadastrados.`,
    typeGetElement: () => { return document.getElementsByClassName('data-amount')[0] },
    print: true,
    positionTagFocus: 3,
    styleBallonBefore: 'left',
    showBotSpeak: true,
    styleBotSpeakAndBallon: { top: '370px', width: '400px', height: '280px', left: '200px' },
    sizePrint: {
      width: 0,
      height: 0,
      left: -10,
      top: -10
    },
    marginPrint: 20,
    showBottomNext: true,
    scrollElement: 0
  },
  {
    text: `Clique em "Card do bot" para prosseguir!`,
    typeGetElement: () => { return document.getElementsByClassName('item-bots')[0] },
    styleBallonBefore: 'left',
    showBotSpeak: true,
    styleBotSpeakAndBallon: { top: '370px', width: '530px', height: '280px', left: '100px' }
  }
]

export {
  PageCompany,
  PageBots
}
/* eslint-disable */
