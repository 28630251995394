import { mapGetters, mapMutations, mapActions } from 'vuex'
import {
  Logout,
  AddButton,
  ListBots,
  SvgLoader
} from '@/components'
import { PageBots } from '@/helper/guied-navigation'

export default {
  data () {
    return {
      isActiveBackButton: true,
      tour: PageBots
    }
  },
  components: {
    Logout,
    AddButton,
    ListBots,
    SvgLoader
  },
  mounted () {
    this.SET_SELECTED_BOT()
    this.CLEAR_CATEGORIES_INTENTION_STORE()
    this.CLEAR_CATEGORIES_RESOURCE_STORE()
    this.CLEAR_INTENTIONS_STORE()
    this.CLEAR_RESOURCES_STORE()
    if (this.$getterNextPage) {
      setTimeout(() => {
        this.$actionNavigationStep(this.tour)
        // TODO: remover quando for implementar o tutorial
        this.$actionStartGuideNavigation(false)
      }, 100)
    }
  },
  computed: {
    ...mapGetters([
      'getterSelectedCompany',
      'getterBots',
      '$getterNextPage',
      '$getterStartGuideNavigation'
    ])
  },
  methods: {
    ...mapMutations([
      'CLEAR_BOTS_STORE',
      'SET_SELECTED_BOT',
      'SET_SELECTED_COMPANY',
      'CLEAR_CATEGORIES_INTENTION_STORE',
      'CLEAR_CATEGORIES_RESOURCE_STORE',
      'CLEAR_INTENTIONS_STORE',
      'CLEAR_RESOURCES_STORE'
    ]),
    ...mapActions([
      '$actionNavigationStep',
      '$actionStartGuideNavigation',
      '$actionNextStepNavigation'
    ]),
    backToCompany () {
      this.CLEAR_BOTS_STORE()
      this.SET_SELECTED_COMPANY()
      this.$router.push({ name: 'Companies' })
    },
    showModalNewBot () {
      this.$eventBus.$emit('ManagerNewBot')
      // this.$actionNextStepNavigation()
    }
  }
}
