var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper bots"
  }, [_c('header', [_c('section', [_c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.auto",
      value: {
        content: _vm.$t('bot.goBack'),
        offset: 10
      },
      expression: "{ content: $t('bot.goBack'), offset: 10 }",
      modifiers: {
        "auto": true
      }
    }],
    staticClass: "btn-back",
    class: {
      'disabled': !_vm.isActiveBackButton
    },
    on: {
      "click": function click($event) {
        return _vm.backToCompany();
      }
    }
  }, [_c('svgLoader', {
    attrs: {
      "src": "/img/icon-back.svg",
      "color": "#ffffff"
    }
  })], 1), _c('div', {
    staticClass: "container-company-logo"
  }, [_c('div', {
    staticClass: "company-logo"
  }, [_c('div', {
    staticClass: "container-logo",
    class: "".concat(_vm.getterSelectedCompany.logo ? 'with-logo' : 'without-logo')
  }, [_vm.getterSelectedCompany.logo ? _c('img', {
    attrs: {
      "src": _vm.getterSelectedCompany.logo
    }
  }) : _c('img', {
    attrs: {
      "src": "/img/logo-simple.svg",
      "alt": ""
    }
  })]), _c('h6', [_vm._v(_vm._s(_vm.getterSelectedCompany.name))])])]), _c('button', {
    staticClass: "logo",
    on: {
      "click": function click($event) {
        return _vm.backToCompany();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/logo.svg"
    }
  })])])]), _c('listBots', {
    on: {
      "callbackRequisition": function callbackRequisition(value) {
        return _vm.isActiveBackButton = value;
      }
    }
  }), _c('addButton', {
    attrs: {
      "on-click": function onClick() {
        return _vm.showModalNewBot();
      },
      "show-message": _vm.getterBots !== null && _vm.getterBots.length === 0 && !_vm.$getterStartGuideNavigation,
      "title": _vm.$t('bot.clickAddBot')
    }
  }), _c('logout')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }